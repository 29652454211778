import React from 'react';

interface ServiceComponentProps {
  title: string;
  description: string;
  align: boolean; // true could mean right-aligned, false for left-aligned, as an example
}

const ServiceComponent: React.FC<ServiceComponentProps> = ({ title, description, align }) => {
    const paragraphs = description.split('\\n').filter(paragraph => paragraph.length > 0);

    return (
      <div className={`serviceComponent ${align ? 'align-right' : 'align-left'}`}>
        <h2>{title}</h2>
        {paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    );
};

export default ServiceComponent;