import React from 'react';

interface ProjectComponentProps {
  title: string;
  roles: string[];
  tags: string[];
  logo: string;
  description: string;
  button: string;
  buttonColor:string;
  image: string;
  link: string;
  align: boolean;
}

const ProjectComponent: React.FC<ProjectComponentProps> = ({ title, roles, tags, logo, description, button, buttonColor, image, link, align }) => {
  // Constant for project information
  const projectInfo = (
    <div className='projectInfo'>
      <h1>
        {roles.map((role, index) => (
          <React.Fragment key={index}>
            <span>{role}</span>
            {index < roles.length - 1 && " | "}
          </React.Fragment>
        ))}
      </h1>
      <div className='tags'>
        {tags.map((tag, index) => (
          <h2 key={index}>{tag}</h2>
        ))}
      </div>
      
      <img src={logo} alt={title + ' logo'} />
      <p>{description}</p>
      <button 
        style={{ backgroundColor: buttonColor }}
        disabled={link === ""}
        onClick={() => {
          if(link !== "") {
            window.open(link, '_blank', 'noopener,noreferrer');
          }
        }}>
        {button}
      </button>
    </div>
  );

  // Constant for project image
  const projectImg = (
    <div className='projectImg'>
      <img src={image} alt={title + ' image'} />
    </div>
  );

  return (
    <div className={`projectComponent ${align ? 'align-right' : 'align-left'}`}>
      {align ? projectImg : null}
      {projectInfo}
      {!align ? projectImg : null}
    </div>
  );
};

export default ProjectComponent;
